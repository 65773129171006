import { render, staticRenderFns } from "./BugCommit.vue?vue&type=template&id=53ed9566&scoped=true&"
import script from "./BugCommit.vue?vue&type=script&lang=js&"
export * from "./BugCommit.vue?vue&type=script&lang=js&"
import style0 from "./BugCommit.vue?vue&type=style&index=0&id=53ed9566&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ed9566",
  null
  
)

export default component.exports